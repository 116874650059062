*:focus {
    outline: none;
  }
  *:focus {
    outline-color: transparent; /* or specify a color */
    outline-style: solid; /* or dashed, dotted, etc. */
    outline-width: 2px; /* or specify the width you prefer */
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  /* Increase specificity to override browser defaults */
  button:focus,
  a:focus {
      outline: none;
  }
  
  button:focus,
  button:active,
  button::-moz-focus-inner {
      outline: none; /* OdstrĂˇni modrĂ˝ obdÄşĹľnik */
  }
  
 @keyframes rotateButton {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.svgLoaderButton.rotating {
  animation: rotateButton .5s linear infinite;
}


@keyframes rotateOnce {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.anotherMediaItem{
  height: 160px; width:100%; cursor: pointer; display: flex; flex-direction: column; justify-content: center; align-items: center; background:#42404082;border-radius:15px
}

.blurbg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit; /* Dediť pozadie */
  background-size: cover;
  background-position: center;
  filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.5); /* Čierny farebný filter s 50% priehľadnosťou */
  z-index: 1;
}
.portrait{height:100vh;width:auto!important;z-index: 9999999;}

  /* Pridajte štýly pre infoContainer */
        #infoContainer {
		padding:20px;
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.85);
            color: white;
            z-index: 9999;
            
			overflow-y: scroll;
        }
        #infoContent {
          
            color:white;
           font-weight:bold;
        }
		#infoContent strong{
          
            color:white;
            padding: 20px;
            border-radius: 8px;
            max-width: 900px;
            margin: auto;
        }
		#infoContent h2,#infoContent p{
            
            color:white;
           
        }
        .close-btn {
            background: none;
            color: white;
            border:2px solid white;
            padding: 10px;
            cursor: pointer;
            border-radius: 5px;
            margin-top: 10px;
        }

.icon-i{
opacity: 0.2;
z-index:99999;
cursor:pointer;
position:absolute;
bottom:5px;left:7px
}
.tags{
  justify-content: center;
  display: flex;
bottom:5px;
position: absolute;
margin:10px;
width:100%;

} 
.tags span{
  border-radius: 3px;
  font-weight: 500;
font-size: 1.3em;
margin: 7px;
padding:7px;
color:white;
  background-color: rgba(0, 0, 0, 0.519);
}

#fullscreenContainer{
  height:100vh;
  width:100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
#fullscreenContainer a{
  border:0;
  background-color: transparent;
  cursor: pointer;
  color: white;
  
  height:100vh;
  width:100%;
  position: fixed;
  left: 0;
  top: 0;
}
#fullscreenContainer span{
 
  color: white;
  
}

.svgLoaderButton.rotateOnce {
  animation: rotateOnce .5s linear;
}

#conatainerMenuFirst{
  z-index: 1005;
  height:60px;
width:100%;
position: fixed;
 bottom:0;
  left: 0;
display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

#conatainerMenu{
  
   right: 60px;
    bottom: 0;
    position: absolute;
    z-index: 998;
    flex-direction: row-reverse;
    justify-content: end;
    width: auto;
    transition: transform .3s;
    display: flex;
}

.hiddenMenu {
  transform: translateX(250px);
  z-index:9999

}

#media-header {
  padding: 0!important;
            position: relative;
            display: inline-block;
            cursor: pointer;
        }

        .svgLoaderButton {
            transition: transform 0.3s;
        }

        #media-header::before {
            content: "open media clipboard";
            font-weight: normal;
            position: absolute;
            bottom: 140%;
            left: 50%;
            transform: translateX(-50%) translateY(10px);
            opacity: 1;
            white-space: nowrap;
            background-color: black;
            color: white;
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 12px;
            pointer-events: none;
            transition: transform 0.3s, opacity 0.3s;
        }

        #media-header:hover .svgLoaderButton {
            transform: translateY(10px);
        }

        #media-header:hover::before {
            transform: translateX(-50%) translateY(0);
            opacity: 1;
        }


  /* Indikátor načítania */
.loading-indicator {
 position: fixed;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: white;
  z-index: 999999;
  font-weight: bold;
  background: #0000004d;
  width: 100%;
  
  top:50%;
  left: 0;
  right:0
}
  /* Pridáme štýl pre zobrazenie textu "Copied!" */
.copied-text {
  position: absolute; /* Umiestni text absolútne v rámci rodiča */
  top: 50%; /* Stred textu vertikálne */
  left: 50%; /* Stred textu horizontálne */
  transform: translate(-50%, -50%); /* Umiestnenie presne do stredu */
  background: rgba(0, 0, 0, 0.75); /* Tmavé pozadie s miernou priehľadnosťou */
  color: white; /* Biela farba textu */
  padding: 10px; /* Padding okolo textu */
  border-radius: 5px; /* Zaoblené rohy */
  font-size: 14px; /* Veľkosť písma */
  font-weight: bold; /* Tučné písmo */
  text-align: center; /* Text centrovaný */
  z-index: 10; /* Nad všetkým */
  pointer-events: none; /* Nezachytáva kliknutia */
  opacity: 0;
  transition: opacity 0.5s; /* Animácia opacity */
}

.media-item {
  position: relative; /* Rodič musí byť relatívny pre správnu pozíciu textu */
}
.menu-item{

opacity: 0; /* Skryjeme prvky na začiatku */
    transition: opacity 1s ease; /* Pridáme prechod pre efekt */
border-radius: 15px;
 display: flex; /* Použitie flexboxu na vycentrovanie */
  justify-content: center; /* Horizontálne vycentrovanie */
  align-items: center; /* Vertikálne vycentrovanie */
  flex-direction: column;
    height: auto;
    text-align: center;
    padding: 10px;
    max-height: 100%;
    height: 300px;
    margin: 10px;
	 position: relative; /* Umožňuje absolútne umiestnenie vnútorných prvkov */
    overflow: hidden; /* Skryje prebytočné časti */
    transition: transform 0.5s ease; /* Prechod pre transformáciu */
  
}
.menu-item.loaded {
    opacity: 1; /* Zobrazíme prvky po načítaní */
}
.menu-item:hover {
  
	 transform: scale(0.97); /* Priblíženie pri hover */
}





/* Základné štýly pre #menu */
#menu {
  -ms-overflow-style: none;
  scrollbar-width: none;
  text-transform: lowercase;
  flex-flow: wrap;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  width: 100%;
  height: auto;
  max-height: 100%;
  display: grid;
  position: relative;
  top: 0;
  overflow-x: scroll;
}



#forfullsreen{
 width: 100vw; /* 100% šírky obrazovky */
    height: 100vh; /* 100% výšky obrazovky */
    overflow-x: hidden; /* Skrytie posuvníkov */
	overflow-y: scroll; /* Skrytie posuvníkov */
    position: fixed; /* Fixuje pozíciu */
    top: 0; /* Nuluje horný okraj */
    left: 0; /* Nuluje ľavý okraj */
    background-color: #000; /* Pozadie čierne, ak je potrebné */
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
   transition: opacity 0.5s ease; /* Fade effect */
}

 .hidden {
            opacity: 0; /* Fade out */
        }

       

.copied-text {
  opacity: 1;
}

#wrapper {background:black} 

  
  /* Kontejnér pre médiá */
  .media-container {
  width:100%;
    background-color: #000000;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 5px;
    padding: 0px;
    position: absolute;
    z-index: 1001;
  }
  
  /* Prispôsobenie pre malé obrazovky */
  @media (max-width: 600px) {
    .media-container {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  }
  
  /* Prispôsobenie pre stredne veľké obrazovky */
  @media (min-width: 601px) and (max-width: 1200px) {
    .media-container {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
  
  /* Štýlovanie mediálnych prvkov */
  .media-item {
    background: #000000;
   cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  
  .media-item img,
  .media-item video,
  .media-item audio {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .media-item a {
    display: block;
    padding: 10px;
    text-align: center;
    color: #0073e6;
    text-decoration: none;
  }
  
  
  .openPro{
    
    text-decoration: underline
    ;
    pointer-events: none;
    cursor: default;
  }
  
  #buttonContainer{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    background-color:rgba(0,0,0,.8);
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    visibility:hidden;
    transition:all 0.3s ease-in-out;
    
    
  
  }
  .button {
    
    flex: 1 0 auto;
    border: none;
    cursor: pointer;
    outline: none;
  } 
  
  .headText{
    z-index: 99999999;
    width: fit-content;
   position: relative;
    z-index: 99;
    max-width: 600px;
    padding: 10px;
    background-color:#ffffff66;
    opacity: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   /*   text-align: justify; */
   
    animation-fill-mode: both; /* PonechÄ‚Ë‡ koneĂ„Ĺ¤nÄ‚Ëť stav animace */
  }
  
  @keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  @keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-30px);
    }
  }
  @keyframes slideInText {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  @keyframes slideOutText {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(40px);
    }
  }
  
  .head {
  
    position: relative;
    z-index: 9999;
    text-align: center;
    color: #fdfdfe;
    font-size: 10vw; /* VeÄľkosĹĄ textu je 10% ĹˇĂ­rky viewportu */
    opacity: 0;
    animation-fill-mode: both; /* PonechÄ‚Ë‡ koneĂ„Ĺ¤nÄ‚Ëť stav animace */
    z-index: 9999999;
  }
  
  .slideOut {
    animation: slideOut 1s forwards;
  }
  
  .slideIn {
    animation: slideIn 1s forwards;
  }
  
  .swiper-slide:first-child .head {
    opacity: 1; /* PrvnÄ‚Â­ slide bude vÄąÄľdy viditelnÄ‚Ëť */
  }
  
  .slideOutText {
    animation: slideOutText 2s forwards;
  }
  
  .slideInText {
    animation: slideInText 2s forwards;
  }
  
  .swiper-slide:first-child .headText {
    opacity: 1; /* PrvnÄ‚Â­ slide bude vÄąÄľdy viditelnÄ‚Ëť */
  }
  
  
  
  .audioOutDiv {
    padding: 5px;
    position: fixed;
      left: 0;
      bottom: 0;
      width: 100%; /* Alebo inĂˇ vhodnĂˇ ĹˇĂ­rka podÄľa dizajnu */
    opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease,  padding 0.3s ease;
      height: auto;
      padding: 0;
      overflow: hidden;
      background-color: #f1f3f4;
  }
  .audioOutDiv.show {
    
    opacity: 1;
      visibility: visible;
      height: auto;
      padding: 5px; /* prispĂ´sobte podÄľa potreby */
  }
  
  
  @media screen and (max-width: 1000px) {
    .audioOutDiv{bottom:55px;}
  
  }
  
   @media screen and (max-width: 400px) {
    .headText{font-size:5vw!important}
  
  }
  
  .audio-controls {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  }
  .rewbutt{
  width: 50px!important;
    height: 50px!important;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 70px;
    bottom: 10px;
  }
  .tickButton {
  width: 1px !important;
    height: 1px !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    right: 70px;
    bottom: 30px;
    padding:2px!important
  }
  
  .audio-controls button {
    font-weight: 400;
    text-shadow: 0 0 3px #b393d3, 0 0 10px #b393d3, 0 0 5px #b393d3, 0 0 20px #b393d3;
    box-shadow: 0px 0px 8px #f1f0f1, 0px 0px 1px #bbb3c4, 0px 0px 1px #8e7c9f,
      0px 0px 10px #b393d3;
  width: 150px;
  height: 150px;
  background-color:transparent; /* Adjust color and opacity */
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 50%;
  }
  
  .audio-controls button:hover{
   
    box-shadow: 0px 0px 3px #f1f0f1, 0px 0px 1px #bbb3c4, 0px 0px 30px #8e7c9f,
      0px 0px 10px #b393d3;
  }
  
  
  .audio-controls button:active {
    box-shadow: 0px 4px 12px rgba(0, 123, 255, 0.5); /* InĂ˝ box-shadow pre aktĂ­vny stav */
  }
  
  
  .hideplay{
    font-size: 27px;
      font-weight: 400;
    color: white;
    text-shadow: 0px 0px 5px #f1f0f1, 0px 0px 10px #bbb3c4, 0px 0px 7px #8e7c9f,
    0px 0px 10px #b393d3;
    cursor: pointer;
  border-radius: 0%!important;
  border:0!important;
  width: auto!important;
  height: 30px!important;
  background-color:transparent!important;
  padding: 10px 15px;
  }
  .audio-controls {
    position: relative; /* UmoĹľĹuje relatĂ­vnu pozĂ­ciu pre ÄŹalĹˇie Ăşpravy */
  }
  
  .audio-controls audio {
    background-color: #f1f3f4; /* Farba pozadia prehrĂˇvaÄŤa */
    color: #333; /* Farba textu v prehrĂˇvaÄŤi */
    width: 100%; /* Ĺ Ă­rka prehrĂˇvaÄŤa */
    padding: 10px; /* Odstup okolo prehrĂˇvaÄŤa */
    border-radius: 5px; /* ZaoblenĂ© rohy prehrĂˇvaÄŤa */
    transition: background-color 0.3s ease; /* PrechodovĂ˝ efekt pre farbu pozadia */
  }
  
  .audio-controls audio:focus {
    outline: none; /* OdstrĂˇni ohraniÄŤenie prehrĂˇvaÄŤa pri zameranĂ­ */
  }
  
  /* Pridanie hover efektu na audio prehrĂˇvaÄŤ */
  .audio-controls audio:hover {
    background-color: #e2e6ea; /* FarebnĂ˝ hover efekt */
  }
  
  .audio-controls audio {
  margin: 20px auto;
  background-color: #f1f3f4; /* Farba pozadia prehrĂˇvaÄŤa */
      color: #333; /* Farba textu v prehrĂˇvaÄŤi */
      /* ÄŽalĹˇie vlastnosti, ktorĂ© mĂ´Ĺľete upraviĹĄ podÄľa potreby */
  position: absolute;
  z-index: 999999;
  left:0;
  right:0;
  width: 80%!important; /* Ensure audio player fits the container */
  border-radius: 0!important; /* Remove border-radius */
  }
  html {height:100%}
  body {
  background:black;
    height:100%;
      margin: 0;
  }
  h1 {
      color: hotpink;
   
  }
  .swiper {
      width: 100%;
      height: 100vh;
  }
  .full {
    opacity: 0.8;
    margin: 5px 0px;
    cursor: pointer;
    width: 50px;
    height:50px;
  background-color: transparent; /* Adjust color and opacity */
  color: white;
  border: none;
  padding:0 5px;
     
  }
  
    .send {
    opacity: 0.8;
    margin: 5px 0px;
    cursor: pointer;
    width: 50px;
    height:50px;
  background-color: transparent; /* Adjust color and opacity */
  color: white;
  border: none;
  padding:0 5px;
     
  }
  
  
  
  .reload {
  opacity: 0.7; 
    margin: 5px 0px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0 5px;
   
	}
  
  .swContainer {
    z-index: 1;
      display: flex !important;   
      flex-direction: column;
      align-items: center;
      justify-content: center;  
  }
  
   
  .swiper-slide {
      background-color: rgb(0, 0, 0);
      position: relative; /* Ensure slide content is positioned correctly */
  }
  .swiper-slide img, .swiper-slide video {
    position: absolute;
      display: block;
      height: 100vh;
      width: 100%;
      object-fit: cover;
  }
  body{
    font-family: 'Trebuchet MS', sans-serif;
  margin: 0;
  background-color: rgb(255, 255, 255);
  }
  h1 {
    color: hotpink;
    
  }
  .swiper {
   
    width: 100%;
    height: 100vh;
  }
  
  
  .swiper-slide{background-color: black;}
  .swiper-slide img {
    display: block;
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  .zoom {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  transform-origin: 0 0;
  animation: leaves 15s ease-in-out infinite alternate;
  position: absolute; left: 0; top: 0;   
  transform-origin: center;
  }
  
  
  
  
  @keyframes leaves {
  0% {
    transform: scale(1.0);
  
  }
  100% {
    transform: scale(1.2);
  
  }
  }
  
  .zoom2 {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  transform-origin: 0 0;
  animation: complexZoom 15s ease-in-out infinite alternate;
  position: absolute; left: 0; top: 0;   
  transform-origin: center;
  }
  
  
  
  
  @keyframes complexZoom {
    0% {
      transform: scale(1.0);
    }
    20% {
      transform: scale(1.1);
    }
    40% {
      transform: scale(1.15);
    }
    60% {
      transform: scale(1.2);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.0);
    }
  }
  
  .zoomRotate {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: center;
    animation: complexZoomRotate 15s ease-in-out infinite;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  @keyframes complexZoomRotate {
    0% {
      transform: scale(1.0) rotate(0deg);
    }
    20% {
      transform: scale(1.2) rotate(2deg);
    }
    40% {
      transform: scale(1.4) rotate(-2deg);
    }
    60% {
      transform: scale(1.5) rotate(3deg);
    }
    80% {
      transform: scale(1.2) rotate(-3deg);
    }
    100% {
      transform: scale(1.0) rotate(0deg);
    }
  }
  
  .zoomRotateStep {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: center;
    animation: complexZoomRotateStep 8s steps(4, end) infinite;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  @keyframes complexZoomRotateStep {
    0% {
      transform: scale(1.0) rotate(0deg);
    }
    10% {
      transform: scale(1.05) rotate(2deg);
    }
    25% {
      transform: scale(1.1) rotate(4deg);
    }
    37.5% {
      transform: scale(1.15) rotate(-4deg);
    }
    50% {
      transform: scale(1.2) rotate(6deg);
    }
    62.5% {
      transform: scale(1.15) rotate(-4deg);
    }
    75% {
      transform: scale(1.1) rotate(2deg);
    }
    87.5% {
      transform: scale(1.05) rotate(-2deg);
    }
    100% {
      transform: scale(1.0) rotate(0deg);
    }
  }
  .swing {
  animation: shake1 10s ease-in-out infinite alternate;
   display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; 
  position: absolute; left: 0; top: 0;   
     transform-origin: center;		
  }
  
  @keyframes shake1 {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  
  .blur{
  animation: blureffect 3s ease-in-out infinite alternate;        
  }
  
  @keyframes blureffect {   
    0% {
        filter: blur(8px);
  -webkit-filter: blur(8px);
    
    }
    100% {
        filter: blur(0px);
  -webkit-filter: blur(0px);
    
    }
  }   
  
  .grayscale {
  filter:grayscale(100%);
  animation: baweffect 3s ease-in-out infinite alternate;        
  }
  
  @keyframes baweffect {   
    0% {
     filter:grayscale(100%);
    
    }
    100% {
       filter:grayscale(0%);
    
    }
  }   
  
  
  @import url(https://fonts.googleapis.com/css?family=Roboto:100,400,900);
  
  :root {
  font-size:16px;
  }
  
  * {
  box-sizing:border-box;
  font-family:"Roboto","Helvetica","sans-serif";
  font-weight: 100;
  color:white;
  }
  
  $th_pink:#E84A5F;
  $th_dark:#2a363b;

  .toggle-btnCloseMedia {
    opacity: 0;
    width: 80px;
    height: 50px;
    margin: 5px 0;
    padding: 5px;
    opacity: 0.8;
    display: block;
    z-index: 0z;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color:transparent; /* Pozadí tlačítka */
    color: red;
    border-radius: 5px; /* Zaoblení rohů */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Stín */
}



.toggle-btnCloseMedia .bar {
  width: 26px;
  height: 2px;
  margin: 7px auto;
  background-color: #ff1f1f;
}

/* Styl pro "zaškrtnuté" tlačítko */
.toggle-btnCloseMedia.checked .bar:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.toggle-btnCloseMedia.checked .bar:nth-child(2) {
  opacity: 0; /* Skrýt střední bar */
}

.toggle-btnCloseMedia.checked .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
  
  body {
  margin:0;
  padding:0;
  background-color:darken($th_dark,10%);
  }
  
  main {
  min-width:200px;
  max-width:800px;
  margin:80px auto;
  padding:10px;
  
  .wrap {
    padding:40px 20px;
    background-color:#fff;
    //box-shadow:0 0 10px 2px rgba(0,0,0,.2);
  }
  
  .title {
    font-size:2em;
    text-align:center;
    margin:20px auto 40px;
    text-transform:uppercase;
  }
  }
  
  header nav ul li {
    opacity: 1;
    transform: translateY(0); /* PredvolenĂˇ transformĂˇcia */
    transition: transform 0.3s ease; /* PridanĂˇ animĂˇcia pre transformĂˇciu */
  }
  
  header {  
  nav {
    font-size: 80%;
    line-height: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#000000;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    visibility:hidden;
    transition:all 0.3s ease-in-out;
    
    ul {
      list-style-type:none;
      padding:0;
      margin:0;
      
      transition:all 0.3s ease-in-out;
     // transform:translateY(-10%);
    
      li {
       // transform:translateY(50px);
        opacity:1;
        
        a {
          display:block;
          font-size:2em;
          text-decoration:none;
          padding:10p;
          text-align:center;
          color:#fff;
          font-weight:bold;
          transition:all 0.2s ease-in-out;
          text-shadow:1px 1px 2px rgba(0,0,0,.5);
          
          &:hover {
            color:$th_pink;
            color:red;
          }
        }
      }
    }
  }
  }
  
  .audio-controls {
    margin-top:15px;
  position: relative; /* Ensure proper positioning */
  }
  
  .audio-controls.show-audio-controls {
  display: block !important; /* Ensure audio controls are always visible */
  }
  #hideMenu{
    cursor: pointer;
    outline: 0;
    background: transparent;
    border: 0;
    opacity: 0.8;
  }
  #playSwiper{
    cursor: pointer;
    outline: 0;
    background: transparent;
    border: 0;
    opacity: 0.8;
  }
  .toggle-btn {
    width: 80px;
    height: 50px;
    margin: 5px 0;
    padding: 5px 5px;
  opacity: 0.8;
  display:block;
  z-index: 1002;
  cursor:pointer;
  position:fixed;
  bottom:0;
  right:0;
  
  
  .bar {
    width:26px;
    height:2px;
    margin:7px auto;
    background-color:#535353;
    transition:all 0.3s ease-in-out;
    
    
    &:nth-child(2) {
      width:20px;
    }
  }
  }
  
  #toggle:checked {
  &~nav {
    z-index: 999;
    opacity:1;
    visibility:visible;
  
    ul {
     // top:7px;
      
      li {
        transform:translateY(0px);
        opacity:1;
  
        @for $i from 1 through 4 {
            $t: $i * 0.1;
            &:nth-child(#{$i}) {
              transition:all 0.3s cubic-bezier(.6,0,.8,1.5) #{$t}s; 
            }
          }
      }
    }
  }
  
  &+label.toggle-btn {
    .bar {
      background-color:red;
  
      &:nth-child(2) {
        transform:translateX(50px);
        opacity:0;
      }
  
      &:nth-child(1) {
        transform:translateY(10px) rotate(45deg);
      }
  
      &:nth-child(3) {
        transform:translateY(-8px) rotate(-45deg);
      }
    }
  }
  }

  /* Pre obrazovky s maximálnou šírkou 1200px */
@media (max-width: 1200px) {
  #menu {
    grid-template-columns: auto auto auto;
  }
}

/* Pre obrazovky s maximálnou šírkou 992px */
@media (max-width: 992px) {
  #menu {
    grid-template-columns: auto auto auto;
    justify-content: center; /* Ak potrebuješ toto použiť */
  }
}

/* Pre obrazovky s maximálnou šírkou 768px */
@media (max-width: 868px) {
 
  #menu {
  overflow-y: scroll;
    font-size: 60%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ak potrebuješ vycentrovať obsah vertikálne */
  }
  .menu-item{height:50%;min-width:150px}
}


/* Pre obrazovky s maximálnou šírkou 576px */
@media (max-width: 576px) {
  .anotherMediaItem{
    height: 130px; width:100%; cursor: pointer; display: flex; flex-direction: column; justify-content: center; align-items: center; background:#42404082;border-radius:15px
  }
  #menu {
  overflow-y: scroll;
    font-size: 60%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ak potrebuješ vycentrovať obsah vertikálne */
  }
  .menu-item{height:40%;min-width:150px}
  #conatainerMenuFirst{
    height:60px;
    width:100%;
    position: fixed;
     bottom:0;
      left: 0;
    display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease;
    }
    
    #conatainerMenu{
      
      left: 0;
        bottom: 0;
        position: absolute;
        z-index: 998;
        flex-direction: row-reverse;
        justify-content: end;
        width: 85%;
        transition: transform .3s;
        display: flex;
        justify-content: space-evenly;
    }
    
    .hiddenMenu {
      transform: translateX(180px);
      z-index:9999
    
    }
.hiddenMenu {
  transform: translateX(80%);
}   
 
}